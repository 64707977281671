<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { useCartStore } from '~/stores/cart';
import { useCommonStore } from '~/stores/common';
import { usePromoSideModalStore } from '~/stores/promoSideModal';
import { getText } from '~/utils/texts.ts';

const route = useRoute();
const nuxtApp = useNuxtApp();
const config = useRuntimeConfig();
const cities = useCities();

if (['index', 'index-with-city'].includes(route.name)) {
    useHead({
        title: getText('index.title'),
        meta: [
            { name: 'description', content: getText('index.description') }
        ],
    });
}


definePageMeta({
    scrollToTop: false
});

useInsider().page("Home", "home", {});

const device = useDevice();

const authStore = useAuthStore();
const cartStore = useCartStore();
const { user } = storeToRefs(authStore);
const commonStore = useCommonStore();
const promoSideModalStore = usePromoSideModalStore();

const fetchReviewOrder = async () => {
    try {
        const res = await useApiFetch('orders/review', { lazy: true });
        return res;
    } catch (error) {
        return null;
    }
};

const fetchBanners = async () => {
    try {
        const res = await useApiFetch("v2/banners", {
            tempGuest: true,
            params: {
                "type": "site"
            }
        });
        return res;
    } catch (error) {
        console.log({ error });
        return null;
    }
};

const appPopupCookie = useCookie('app-popup', {
    maxAge: 60 * 60 * 24,
    expires: new Date((new Date).getDate() + 1)
});

const sushiPopupCookie = useCookie('sushi-popup', {
    maxAge: 60 * 60 * 24 * 7,
    expires: new Date((new Date).getDate() + 1)
});

const [data, { data: orderReviewData }, { data: banners }] = await Promise.all([
    commonStore.fetchMenu(),
    fetchReviewOrder(),
    fetchBanners()
]);

// const { data } = useApiFetch('menu', {
//     headers: {
//         'app-version': useRuntimeConfig().public.appVersion
//     },
//     lazy: true
// });

// commonStore.fetchMenu(false);
// commonStore.catalog = data.value;

const showAppPopup = ref(false);
const showPromoPopup = ref(false);
const showOrderReviewPopup = ref(orderReviewData.value != null);
const showSushiPopup = ref(false);

if (!showPromoPopup.value && !appPopupCookie.value && !showOrderReviewPopup.value) {
    if (!device.isCrawler) {
        // showAppPopup.value = true;
    }
}

// if (!showPromoPopup.value && !showSushiPopup.value && !sushiPopupCookie.value && !showOrderReviewPopup.value) {
//     if (!device.isCrawler) {
//         if (authStore.user?.address?.organization_id == 94) {
//             showSushiPopup.value = true;
//         }
//     }
// }

nuxtApp.$modalsBus.$on(`modals.app-popup:close`, () => {
    showAppPopup.value = false;
    appPopupCookie.value = true;
});

nuxtApp.$modalsBus.$on(`modals.sushi-popup:close`, () => {
    showSushiPopup.value = false;
    sushiPopupCookie.value = true;
});

nuxtApp.$modalsBus.$on(`modals.review-popup:close`, () => {
    showOrderReviewPopup.value = false;
});

nuxtApp.$modalsBus.$on(`modals.promo-popup:close`, () => {
    showPromoPopup.value = false;
});

nuxtApp.$modalsBus.$on(`modals.promo-popup:open`, () => {
    showPromoPopup.value = true;
});

// await commonStore.fetchMenu();

const showProductModal = computed(() => {
    return route.matched.filter(el => el.path.includes("/products/:id") || el.path.includes("/halves/:id")
        || el.path.includes("/groups/:id")).length;

});

const showAuthModal = computed(() => {
    return route.matched.filter(el => el.path.includes("/auth/login") || el.path.includes("/auth/new-login")
        || el.path.includes("/auth/profile")).length;
});

const showRegisterModal = computed(() => {
    return route.matched.filter(el => el.path.includes("/auth/register")).length;
});

const showAddressModal = computed(() => {
    return route.matched.filter(el => el.path.includes("/address")).length;
});

const showCartModal = computed(() => {
    return route.matched.filter(el => el.path.includes("/cart")).length;
});
</script>

<template>
    <UiModalsSideModal :show="promoSideModalStore.showModal" :data="promoSideModalStore.promoData" />
    <HeaderBannerCarousel :banners="banners" />

    <HeaderActiveOrders />

    <CatalogCategories v-if="commonStore.catalog" :items="commonStore.catalog.categories" />
    <div v-if="commonStore.catalog != null" class="container-lg">
        <CatalogProductsGrid :items="commonStore.catalog.categories" />
    </div>

    <div v-if="config.public.isDomino" class="container-lg s-text-block">
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h1>Доставка пиццы {{ cities.getCurrentCity().seoInCity }}</h1>
                    <p>
                        Ищете самую вкусную и сочную пиццу с доставкой {{ cities.getCurrentCity().seoInCity }}? Добро
                        пожаловать в DOMINO PIZZA! Мы
                        гордимся нашей пиццей, приготовленной по классическим рецептам с использованием только свежих и
                        качественных ингредиентов. Наши пиццы - это идеальное сочетание пышного теста, богатой начинки и
                        ароматного томатного соуса.
                    </p>
                </div>
                <div>
                    <h2>Акции и Скидки</h2>
                    <p>
                        Мы радуем своих клиентов не только вкусной пиццей, но и приятными акциями для больших и
                        маленьких
                        компаний с максимальной выгодой
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <h2>Удобная и быстрая доставка</h2>
                    <p>
                        Мы понимаем, как важно получить пиццу быстро и без задержек, поэтому наши курьеры работают
                        оперативно сохраняя её теплоту и свежесть
                    </p>
                </div>
                <div>
                    <h2>DOMINO PIZZA – всегда отличная идея!</h2>
                    <p>
                        Каждая пицца в DOMINO PIZZA готовится с любовью и вниманием к деталям. Наши повара тщательно
                        следят за качеством каждого ингредиента и строго придерживаются рецептур, чтобы каждая пицца
                        была идеальной. Мы также предлагаем возможность добавить или убрать ингредиенты по вашему
                        желанию, чтобы вы могли создать пиццу своей мечты.
                    </p>
                </div>
            </div>
            <div class="col-12 mt-2">
                <h3>Заказывайте пиццу в DOMINO PIZZA и наслаждайтесь великолепным вкусом, быстрым сервисом и приятными
                    бонусами.</h3>
                <p>
                    Мы готовы радовать вас каждый день, доставляя горячую и ароматную пиццу прямо к вашему порогу!
                </p>
            </div>
        </div>
    </div>

    <div class="container-lg">
        <div class="floating-cart-button d-block d-md-none">
            <div v-if="!cartStore.firstLoading">
                <CatalogCartButton v-if="!cartStore.isEmpty" />
                <CatalogCartIconButton v-else :withText="true" />
            </div>
        </div>
    </div>

    <transition name="fade">
        <div v-if="showProductModal" class="modal-route">
            <CatalogProductDModal modalId="product">
                <NuxtPage />
            </CatalogProductDModal>
        </div>
    </transition>

    <div v-if="showAuthModal" class="modal-route">
        <AuthDModal modalId="auth">
            <NuxtPage />
        </AuthDModal>
    </div>

    <div v-if="showRegisterModal" class="modal-route">
        <AuthDModal modalId="register" :backdrop="'static'">
            <NuxtPage />
        </AuthDModal>
    </div>

    <div v-if="showAddressModal" class="modal-route">
        <UserAddressDModal modalId="address">
            <NuxtPage />
        </UserAddressDModal>
    </div>

    <!-- <div v-if="showAddressModal" class="modal-route">
        <ClientOnly>
            <UserAddressModal>
                <NuxtPage />
            </UserAddressModal>
        </ClientOnly>
    </div> -->

    <div v-if="showCartModal" class="modal-route">
        <CartModal :backdrop="true">
            <NuxtPage />
        </CartModal>
    </div>

    <ClientOnly v-if="false">
        <transition name="fade">
            <div v-if="showAppPopup">
                <PromoAppModal modalId="app-popup" />
            </div>
        </transition>
    </ClientOnly>

    <ClientOnly>
        <transition name="fade">
            <div v-if="showSushiPopup">
                <PromoSushiModal modalId="sushi-popup" />
            </div>
        </transition>
    </ClientOnly>

    <ClientOnly>
        <transition name="fade">
            <div v-if="showOrderReviewPopup">
                <OrdersReviewModal modalId="order-review-popup" :orderId="orderReviewData" />
            </div>
        </transition>
    </ClientOnly>

    <ClientOnly>
        <transition name="fade">
            <div v-if="showPromoPopup">
                <PromoBonusesPopupModal modalId="promo-bonuses-popup" />
            </div>
        </transition>
    </ClientOnly>
</template>

<style lang="scss" scoped>
.floating-cart-button {
    position: fixed;
    bottom: 20px;
    right: 20px;

    @media screen and (max-width: 760px) {
        right: 50%;
        transform: translateX(50%);
    }
}

.s-text-block {
    margin-top: 80px;
    padding-bottom: 30px;

    h1,
    h2 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h3 {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
    }

    p {
        color: #B6B6B6;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
</style>