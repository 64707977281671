<script setup>
const props = defineProps(['show', 'data']);
const forceHide = ref(false);
const { $dayjs } = useNuxtApp();

const close = () => {
    localStorage.setItem('side-modal.' + props.data.key, $dayjs().toISOString());
    forceHide.value = true;
};
</script>

<template>
    <div v-if="show" class="side-modal" :class="{ show: show && !forceHide }">
        <div @click="close" class="close-button"><i class="bi bi-x"></i></div>
        <div v-if="data.image" class="image">
            <img :src="data.image" :alt="data.title">
        </div>
        <div class="heading">{{ data.title }}</div>
        <p v-html="data.content"></p>
        <DButton v-if="data.button_text" @click="close" class="text-center">{{ data.button_text }}</DButton>
    </div>
</template>

<style lang="scss" scoped>
.side-modal {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999;
    display: none;
    flex-direction: column;
    border-radius: 20px 20px 0px 0px;
    background: #FFF;
    padding: 10px 15px;
    padding-bottom: 40px;
    box-shadow: 0 0 10px #00000021;
    min-width: 320px;
    max-width: 500px;

    @media screen and (max-width: 500px) {
        left: 0;
        width: 100%;
    }

    &.show {
        display: flex;
    }

    .close-button {
        background-color: #fff;
        border-radius: 40px;
        color: #000000;
        cursor: pointer;
        font-size: 30px;
        height: 40px;
        line-height: 0;
        padding: 4px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: -50px;
        width: 40px !important;
        z-index: 100;
        box-shadow: 0 4px 10px #00000021;
    }

    .heading {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .image {
        margin-bottom: 20px;

        img {
            max-width: 100%;
        }
    }

    p {
        color: #646464;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
}
</style>